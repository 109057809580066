<template>
  <div class="mb-1">
    <validation-provider :rules="rules" #default="{ errors }" :name="placeholder.toLowerCase()">
      <label style="margin-bottom: 0px"> {{ placeholder }}</label>

      <input v-if="!isTextarea" :disabled="disabled" class="form-control" v-bind:value="value" :type="type" :name="name"
        v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder.split(' ')[0]" />

      <textarea v-if="isTextarea" class="form-control" :disabled="disabled" v-bind:value="value" :name="name"
        v-on:input="$emit('input', $event.target.value)" :placeholder="placeholder.split(' ')[0]"></textarea>
      <small class="help-text text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </div>
</template>


<script>
import { email } from "@validations";
export default {
  props: ["value", "placeholder", "rules", "type", "name", "disabled", "isTextarea"],

  data: {
    isTextarea: false,
  },

  data() {
    return {
      email,
    };
  },

  methods: {
    updateValue(event) {

      this.$emit("get", event.target.value);
    },
  },
};
</script>
