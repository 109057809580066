<template>
  <div>
    <b-card>
      <div class="timesheet-box">
        <h1 class="text-center mt-3">INFORMATIONS</h1>
        <b-avatar
          badge
          size="100"
          badge-variant="info"
          :src="uploadedUrl || enterprise.logo"
          :icon="'image'"
        >
          <template #badge>
            <input
              type="file"
              accept="image/*"
              hidden
              id="logo"
              @change="onFileUpload"
            />
            <b-icon
              @click="openFilePicker"
              style="cursor: pointer"
              icon="camera"
            ></b-icon>
          </template>
        </b-avatar>
      </div>
      <b-row>
        <b-col cols="auto">
          <div style="margin-left: 120px">
            <h2>{{ enterprise.name }}</h2>
            <h4>{{ enterprise.address }} - {{ country }}</h4>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-overlay :show="loading" rounded="sm">
      <div>
        <b-tabs justified>
          <b-tab title="Détails" active
            ><p>
              <b-card>
                <validation-observer ref="form">
                  <b-row id="form-popover">
                    <b-popover
                      target="form-popover"
                      triggers="manual"
                      placement="topleft"
                      variant="danger"
                      ref="form_popover"
                    >
                      <template #title>Erreur</template>
                      {{ errorMessage }}
                    </b-popover>
                    <b-col md="4">
                      <ky-input
                        :disabled="disabled"
                        placeholder="Nom de l'entreprise"
                        v-model="enterprise.name"
                        :rules="'required'"
                      />
                    </b-col>
                    <b-col md="4">
                      <ky-input
                        :disabled="disabled"
                        placeholder="Adresse de l'entreprise"
                        v-model="enterprise.address"
                        :rules="'required'"
                      />
                    </b-col>
                    <b-col md="4">
                      <ky-input
                        v-if="disabled"
                        :disabled="disabled"
                        placeholder="Date de création"
                        v-model="enterprise.creation_date_format"
                        :rules="'required'"
                      />
                      <ky-input
                        v-else
                        :disabled="disabled"
                        placeholder="Date de création"
                        v-model="enterprise.creation_date"
                        :rules="'required'"
                        :type="'date'"
                      />
                    </b-col>
                    <b-col md="4">
                      <ky-input
                        :disabled="disabled"
                        placeholder="Accronyme"
                        v-model="enterprise.short_name"
                      />
                    </b-col>

                    <b-col md="4">
                      <ky-input
                        :disabled="disabled"
                        placeholder="Identifiant"
                        v-model="enterprise.identifiant"
                      />
                    </b-col>

                    <b-col md="4">
                      <b-form-group
                        id="input-group-1"
                        label="Pays"
                        label-for="input-1"
                      >
                        <b-form-select
                          :disabled="disabled"
                          id="inline-form-custom-select-pref"
                          class="mb-2 mr-sm-2 mb-sm-0"
                          :options="[
                            { name: 'Sélectionnez un pays', value: null },
                            ...countries,
                          ]"
                          value-field="id"
                          text-field="name"
                          v-model="enterprise.country_id"
                          :rules="'required'"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-btn v-if="!disabled" @click="saveInfo">
                        Enregistrer
                      </b-btn>
                    </b-col>
                  </b-row>
                </validation-observer>
                <b-button
                  v-show="disabled"
                  @click="disabled = false"
                  v-if="userInfo.role_id == 1"
                >
                  <b-icon icon="pencil-square"></b-icon> Modifier
                </b-button>
              </b-card>
            </p></b-tab
          >
          <b-tab title="Informations bancaires"
            ><p>
              <b-card>
                <validation-observer ref="bankInfosForm">
                  <b-row id="form-popover">
                    <b-popover
                      target="form-popover"
                      triggers="manual"
                      placement="topleft"
                      variant="danger"
                      ref="form_popover"
                    >
                      <template #title>Erreur</template>
                      {{ errorMessage }}
                    </b-popover>
                    <b-col md="4">
                      <ky-input
                        :disabled="disabledBankInfos"
                        placeholder="Titulaire du compte"
                        v-model="bank.account_owner"
                        :rules="'required'"
                      />
                    </b-col>
                    <b-col md="4">
                      <ky-input
                        :disabled="disabledBankInfos"
                        placeholder="Adresse de l'entreprise"
                        v-model="bank.address"
                        :rules="'required'"
                      />
                    </b-col>
                    <b-col md="4">
                      <ky-input
                        :disabled="disabledBankInfos"
                        placeholder="Domiciliation du compte"
                        v-model="bank.domiciliation"
                        :rules="'required'"
                      />
                    </b-col>
                    <b-col md="4">
                      <ky-input
                        :disabled="disabledBankInfos"
                        placeholder="Banque"
                        v-model="bank.bank_id"
                        :rules="'required'"
                      />
                    </b-col>
                    <b-col md="4">
                      <ky-input
                        :disabled="disabledBankInfos"
                        placeholder="Guichet"
                        v-model="bank.guichet"
                      />
                    </b-col>

                    <b-col md="4">
                      <ky-input
                        :disabled="disabledBankInfos"
                        placeholder="N° de compte"
                        v-model="bank.account_number"
                      />
                    </b-col>
                    <b-col md="4">
                      <ky-input
                        :disabled="disabledBankInfos"
                        v-model="bank.rib"
                        placeholder="Clé RIB"
                      />
                    </b-col>
                    <b-col md="4">
                      <ky-input
                        :disabled="disabledBankInfos"
                        v-model="bank.iban"
                        placeholder="IBAN"
                      />
                    </b-col>
                    <b-col md="4">
                      <ky-input
                        :disabled="disabledBankInfos"
                        v-model="bank.bic"
                        placeholder="BIC"
                      />
                    </b-col>
                    <!-- <b-col md="12">
                      <ky-input
                        :disabled="disabledBankInfos"
                        v-model="bank.email"
                        placeholder="Adresse e-mail de facturation"
                      />
                    </b-col> -->

                    <b-col md="4">
                      <b-btn v-if="!disabledBankInfos" @click="saveBankInfos">
                        Enregistrer
                      </b-btn>
                    </b-col>
                  </b-row>
                </validation-observer>
                <b-button
                  v-show="disabledBankInfos"
                  @click="disabledBankInfos = false"
                  v-if="userInfo.role_id == 1"
                >
                  <b-icon icon="pencil-square"></b-icon> Modifier
                </b-button>
              </b-card>
            </p></b-tab
          >
        </b-tabs>
      </div>

      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">Enregistrement...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import kyInput from "@/@core/layouts/components/ky-input.vue";
import { mapActions, mapState } from "vuex";
export default {
  components: { kyInput },
  mounted() {
    this.getCountries().then((r) => {});

    this.getInfo().then((r) => {
      this.enterprise = r.data.data;
      this.showBankInfos();
    });
  },
  data() {
    return {
      enterprise: {},
      loading: false,
      errorMessage: "",
      uploadedUrl: null,
      disabled: true,
      disabledBankInfos: true,
      userInfo: JSON.parse(localStorage.userInfo),
    };
  },

  computed: {
    ...mapState("auth", ["countries"]),
    ...mapState("bank", ["bank"]),

    country() {
      return this.enterprise.country?.name;
    },
  },

  methods: {
    onFileUpload(event) {
      this.uploaded = true;
      var file = event.target.files[0];
      if (file) this.uploadedUrl = URL.createObjectURL(file);

      this.enterprise.logo = file;
      this.enterprise.logo_only = 1;
      this.saveInfo();
    },

    openFilePicker() {
      if (this.userInfo.role_id != 1) return;
      document.getElementById("logo").click();
    },

    async saveInfo() {
      const valid = await this.$refs.form.validate();
      if (!valid) return;
      this.loading = true;
      this.enterprise._method = "PUT";
      this.enterprise.logo_only = 0;
      this.updateEnterprise(this.$formData(this.enterprise))
        .then((r) => {
          this.loading = false;
          this.uploadedUrl = null;
          this.disabled = true;
          this.enterprise = r.data.data;
          this.$bvToast.toast("Sauvegarde éffectuée.", {
            title: "Message",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "primary",
            solid: true,
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error.message;
          this.popover_disabled = false;
          this.$refs.form_popover.$emit("open");
        });
    },

    saveBankInfos() {
      const valid = this.$refs.bankInfosForm.validate();
      if (!valid) return;
      this.loading = true;
      this.updateBankInfos(this.$formData(this.bank))
        .then((r) => {
          console.log("hello");
          console.log(r);
          this.loading = false;
          this.disabledBankInfos = true;
          this.showBankInfos();
          this.$bvToast.toast("Sauvegarde éffectuée.", {
            title: "Message",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "primary",
            solid: true,
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error.message;
          this.popover_disabled = false;
          this.$refs.form_popover.$emit("open");
        });
    },

    ...mapActions("auth", ["getCountries"]),
    ...mapActions("enterprise", ["getInfo", "updateEnterprise"]),
    ...mapActions("bank", ["addBankInfos", "showBankInfos", "updateBankInfos"]),
  },

  watch: {
    enterprise: {
      handler() {
        this.$refs.form_popover.$emit("close");
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.timesheet-box {
  height: 20vh;
  background: #181a29;
  border-top: 1px solid #c5cce9;
  border-bottom: 1px solid #c5cce9;
  margin-bottom: 10px;
}
</style>

